@import "@/styles/variables.module.scss";

.breadcrumbs {
    &__link {
        color: $black-color;
        text-decoration: none;
        transition: .2s ease;

        &:hover {
            color: $accent;
        }
    }

    & ol {
        justify-content: center;
    }
}