.hero {
    &__wrapper {
        display: flex;
        gap: 80px;
        flex-direction: row;

        &_reverse {
            flex-direction: row-reverse;
        }

        @media(max-width: '1024px') {}

        @media(max-width: '768px') {
            flex-direction: column-reverse;
            gap: 0;
        }
    }

    &__left {
        width: 60%;

        @media(max-width: '768px') {
            width: 100%;
            padding-top: 20px;
        }
    }

    &__right {
        display: flex;
        align-items: center;
        width: 40%;
        position: relative;
        border-radius: 10px;
        overflow: hidden;

        @media(max-width: '768px') {
            width: 100%;
        }
    }

    &__image {
        position: relative;
        padding-top: 60%;
        flex-grow: 1;
        overflow: hidden;
        border-radius: 15px;

        @media(max-width: '1024px') {
            padding-top: 80%;
        }

        @media(max-width: '768px') {
            padding-top: 40%;
        }
    }

    &__text {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 30px;
        text-transform: uppercase;
    }
}

@media(max-width: 1024px) {
    .hero {

        &__title {
            margin-bottom: 15px;
        }

        &__text {
            margin-bottom: 10px;
        }
    }
}