@import "@/styles/variables.module.scss";

.catalogList {
  margin-bottom: 50px;

  @media screen and (min-width: 768px) {
    margin-bottom: 70px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 104px;
  }

  &__item {
    display: block;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    & a {
      display: block;
      position: relative;
      padding-top: calc(100% / 0.7);
      height: 0;
    }

    & img {
      width: 100%;
      height: 100%;
      transition: transform 0.3s;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  &__buttonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    & button {
      font-size: 12px;
      line-height: 1.21em;
      border-radius: none;
      border: none;
      background-color: transparent;
      text-transform: none;
      color: $gray-transparent;
      border-bottom: 1px solid $gray-transparent;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: $black-color;
      }
    }
  }
}