@import "@/styles/variables.module.scss";

.categories-list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    gap: 20px;

    &__item {
        list-style: none;
        padding-top: 100%;
        position: relative;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset: 0;
        padding: 20px;
        background-color: $white-lilac;
        border-radius: 10px;
        transition: .1s ease;
        color: $black-color;
        text-decoration: none;

        &:hover {
            background-color: $input-light;
        }
    }

    &__name {
        font-size: 0.9rem;
        margin-top: 1em;
        text-align: center;
    }
}

.mobile-category-bars {
    user-select: none;

    &__skeleton {
        display: flex;
        overflow: hidden;
        gap: 10px;
    }

    &__slide {
        width: auto;
    }

    &__link {
        width: 85px;
        display: block;
        text-decoration: none;
    }

    &__image-wrap {
        height: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white-lilac;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    &__name {
        text-align: center;
        color: $black-color;
    }
}

@media (max-width: 1200px) {
    .mobile-category-bars {
        &__name {
            font-size: 0.7rem;
        }
    }
}

@media (max-width: 768px) {
    .mobile-category-bars {
        &__name {
            font-size: 0.9rem;
        }
    }
}