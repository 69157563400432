@import '@/styles/variables.module.scss';

.counter {
    display: flex;
    justify-content: center;
    gap: 10px;

    &_loading {
        .counter__button {
            opacity: 0.1;
        }

        .counter__window {
            animation-name: opacity-loading;
            animation-duration: 0.8s;
            animation-iteration-count: infinite;
        }
    }

    &__button {
        transition: .2s ease;
    }

    &__window {
        min-width: 70px;
        border: none;
        border-radius: 5px;
        background-color: $white-lilac;

        input {
            padding: 0;
            text-align: center;
        }

        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    &__window::after {
        display: none;
    }

    &__window::before {
        display: none;
    }
}

@keyframes opacity-loading {
    0% {

        background-color: rgba(246, 248, 252, 1);
    }

    50% {
        background-color: rgba(246, 248, 252, 0.5);
    }

    100% {
        background-color: rgba(246, 248, 252, 1);
    }
}

@media (max-width: 768px) {
    .counter {
        gap: 0px;
    }
}