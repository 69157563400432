.features {
    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        text-transform: uppercase;

        @media (max-width: 768px) {
            font-size: 12px;
        }
    }

    &Centered {
        width: 100%;

        .features__elements {
            max-width: 1000px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;

            .feature__el {
                display: flex;
                justify-content: center;

                .features__text {
                    text-align: center;
                    flex-direction: column;
                    align-items: center;
                    font-weight: 400;
                    max-width: 80%;
                    font-size: 18px;
                    text-transform: none;
                    gap: 30px 0;

                    @media (max-width: 768px) {
                        max-width: 60%;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .features__elements {
                flex-direction: column;
                gap: 30px;
            }
        }
    }
}
