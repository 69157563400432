@import '@/styles/variables.module.scss';

.addButton {
    font-size: 16px;
    padding: 12px;
    background-color: rgba(254, 203, 0, 0.4);
    transition: background-color .3s ease-in-out;
    color: $black-color;

    &:hover {
        background-color: rgba(254, 203, 0, 0.7);
    }

    &:active {
        background-color: rgba(254, 203, 0, 1);
    }

    &:disabled {
        background-color: #f5f5f5;
    }
}