.blogList {
    margin-bottom: 93px;

    @media (max-width: 1024px) {
        margin-bottom: 81px;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }
}
