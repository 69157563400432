@import "@/styles/variables.module.scss";

.google-reviews {
    &__title {
        margin-bottom: 2em;
    }
}

.review {
    background-color: $white-lilac;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    &__author {
        display: flex;
        gap: 15px;
        align-items: center;
        margin-bottom: 15px;
        color: $black-color;
        text-decoration: none;
    }

    &__photo {
        object-fit: cover;
    }

    &__name {
        font-weight: 600;
    }

    &__text {
        font-size: 0.9em;
        line-height: 1.4;
        overflow: hidden;
    }

    &__button {
        margin-top: 10px;
        text-align: right;
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: $text-gray;
        padding: 4px 8px;
        font-size: 0.8rem;
    }
}

.review-stars {
    margin-bottom: 20px;
}